import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import Divider from '@mui/material/Divider'
import DialogContent from '@mui/material/DialogContent'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import DialogTitle from '@mui/material/DialogTitle'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import CreateTenantDialog from '../CreateTenantDialog/CreateTenantDialog'
import { Container, SpaceBottom } from '../ApplicationStyles'
import Grid from '@mui/material/Unstable_Grid2'
import { AddBoxOutlined } from '@mui/icons-material'
import { useApplicationApiClient, useTenantApiClient } from '../../../Configs/API/createAxiosConfig'
import { TenantSingleType } from '../../Configurations/Tenants/Tenant'
import { dispatchTenantsAPIData } from '../../../Configs/ReduxSlice/tenantSlice'
import { CircularProgress, FormHelperText, Skeleton, Typography } from '@mui/material'
import { payloadObjectVerification } from '../../../components/helpers/payloadObjectVerification'
import { useAuth0 } from '@auth0/auth0-react'
import { addApplicationSlice, dispatchApplicationsAPIData } from '../../../Configs/ReduxSlice/ApplicationSlice'
import { useAppDispatch } from '../../../Configs/store/config/useAppDispatch'
import { useAppSelector } from '../../../Configs/store/config/useAppSelector'
import { applicationType } from '../Applications'
import yaml from 'js-yaml'
import axios from 'axios'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Switch from '@mui/material/Switch'

// Image imports (Replace these paths with actual image URLs or imports)
import ReactLogo from '/static/react.svg'
import HelmLogo from '/static/helm.svg'
import SpringBootLogo from '/static/springboot.svg'
import VueLogo from '/static/vue.svg'
import AngularLogo from '/static/angular.svg'
import NodeJsLogo from '/static/nodejs.svg'
import useAlert from '../../../components/Extras/Alerts/useAlert'
import { useNavigate } from 'react-router-dom'
import ReviewModal from '../ReviewModal/ReviewModal'
import { green } from '@mui/material/colors'

type CreateApplicationDialogProps = {
   openApplicationDialog: boolean
   setOpenApplicationDialog: Function
   technology?: string
}

const CreateApplicationDialog = ({
   openApplicationDialog,
   setOpenApplicationDialog,
   technology,
}: CreateApplicationDialogProps) => {
   const [openTenantDialog, setOpenTenantDialog] = React.useState(false)
   const [selectedTechnology, setselectedTechnology] = React.useState(technology || '')
   const [getTenentAPILoading, setgetTenentAPILoading] = useState<boolean>(false)
   const [getApplicationAPILoading, setgetApplicationAPILoading] = useState<boolean>(false)
   const [tenants, setTenants] = React.useState<TenantSingleType[]>([])
   const [selectedTenant, setselectedTenant] = React.useState('')
   const [selectedIntegration, setSelectedIntegration] = useState<string>('')
   const [newAppName, setnewAppName] = useState('')
   const [kubeNativeFields, setkubeNativeFields] = useState({ repo: '', name: '', version: '', value: '' })
   const [formError, setformError] = useState<string>('')
   const [addApplicationLoading, setaddApplicationLoading] = useState(false)
   const { user } = useAuth0()
   const { showAlert } = useAlert()
   const [enablePublicAccess, setEnablePublicAccess] = useState(false)
   const [serviceName, setServiceName] = useState('')
   const [servicePort, setServicePort] = useState('')
   const navigate = useNavigate();
   const [openIntegrationModal, setOpenIntegrationModal] = useState(false);
   const [useAiAssistant, setUseAiAssistant] = useState(false);
   const [requirement, setRequirement] = useState('')

   const [error, setError] = useState<string | null>(null)

   const tenantService = useTenantApiClient()
   const applicationService = useApplicationApiClient()
   const dispatch = useAppDispatch()

   const { applicationAPIData } = useAppSelector((state) => state.applications)

   const [openReviewModal, setOpenReviewModal] = useState({
      status: false,
      message: '',
      isDelete: false,
   })
   const handleClose = () => {
      setOpenApplicationDialog(false)
   }

   const handleChangeTech = (event: SelectChangeEvent) => {
      setselectedTechnology(event.target.value as string)
   }

   const handleChangeTenant = (event: SelectChangeEvent) => {
      setselectedTenant(event.target.value as string)
   }

   const handleEnablePublicAccessChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setEnablePublicAccess(event.target.checked)
   }

   // Validate Helm chart by calling the backend service
   const validateHelmChart = async (repoUrl: string, chartName: string, chartVersion: string) => {
      try {
         const response = await applicationService.validateHelmChart(repoUrl, chartName, chartVersion)
         if (response.status === 200) {
            console.log('Helm chart is valid')
         } else {
            setformError('Invalid helm chart or version')
            showAlert(response?.response?.data?.errorMessage || 'Failed to validate helm', {
               header: response?.response?.data?.message,
            })
         }
      } catch (error) {
         console.error('Error validating Helm chart:', error)
         setformError('Invalid helm chart or version')
         showAlert('Failed to validate helm', { extraInfo: 'Please try again later' })
      }
   }

   const fetchApplications = async () => {
      try {
         setgetApplicationAPILoading(true)
         const response = await applicationService.getApplications()
         if (response.status === 200) {
            dispatch(dispatchApplicationsAPIData(response.data))
         } else {
            setError('Failed to load Applications. Please try again later.')
            showAlert(response?.response?.data?.errorMessage || 'Failed to fetch Applications', {
               header: response?.response?.data?.message,
            })
         }
         setgetApplicationAPILoading(false)
      } catch (error) {
         console.error('Error fetching Applications', error)
         setError('Failed to load Applications. Please try again later.')
         showAlert('Failed to load Applications', { extraInfo: 'Please try again later' })
      } finally {
         setgetApplicationAPILoading(false)
      }
   }

   const fetchTenants = async () => {
      try {
         setgetTenentAPILoading(true)
         const response = await tenantService.getTenants()
         if (response.status == 200) {
            setTenants(response.data)
            dispatch(dispatchTenantsAPIData(response.data))
         } else {
            setError('Failed to load tenants. Please try again later.')
            showAlert(response?.response?.data?.errorMessage || 'Failed to fetch tenants', {
               header: response?.response?.data?.message,
            })
         }
      } catch (err) {
         console.log('Error fetching tenants', err)
         setError('Failed to load tenants. Please try again later.')
         showAlert('Failed to load tenants', { extraInfo: 'Please try again later' })
      } finally {
         setgetTenentAPILoading(false)
      }
   }
   const clearAllFields = () => {
      setformError('')
      setnewAppName('')
      setselectedTechnology('')
      setselectedTenant('')
   }

   const getAppType = (technology: string) => {
      switch (technology) {
         case 'REACT':
         case 'VUE':
         case 'ANGULAR':
            return 'FRONTEND'
         case 'HELM':
            return 'KUBE_NATIVE'
         case 'SPRINGBOOT':
         case 'NODEJS':
            return 'BACKEND'
         default:
            return 'UNKNOWN'
      }
   }

   const handleAddApplication = async () => {
       resetFormError();

       try {
           if (selectedTechnology === 'HELM') {
               if (!isValidHelmYaml(kubeNativeFields.value)) return;

               if (!await isHelmChartValid(kubeNativeFields.repo, kubeNativeFields.name, kubeNativeFields.version)) {
                   return;
               }
           }

           const payload = constructPayload();
           if (!isPayloadValid(payload)) return;

           if (!isAppNameLengthValid(newAppName)) return;
           if (hasInvalidAppNameCharacters(newAppName)) return;

           await submitApplication(payload);

       } catch (err) {
           handleSubmissionError(err);
       } finally {
           setaddApplicationLoading(false);
       }
   };

   const resetFormError = () => setformError('');

   const isValidHelmYaml = (yamlValue) => {
       try {
           const parsedData = yaml.load(yamlValue);
           console.log('parsedData', parsedData);
           return true;
       } catch (e) {
           setformError('kubeNativeSchema');
           console.error('Invalid YAML file for Helm values');
           return false;
       }
   };

   const isHelmChartValid = async (repo, name, version) => {
       try {
           await validateHelmChart(repo, name, version);
           return true;
       } catch (error) {
           console.error('Error validating Helm chart:', error);
           setformError('Invalid helm chart or version');
           return false;
       }
   };

   const constructPayload = () => {
       const appType = getAppType(selectedTechnology);
       const selectedIntegrationObject = applicationAPIData.find(app => app.id === selectedIntegration);

       const payload = {
           appName: newAppName,
           clientId: user?.sub,
           requirement,
           technology: selectedTechnology,
           appType,
           tenantId: selectedTenant,
           appAttributes: {
               ...(selectedIntegration && selectedIntegrationObject && {
                   integration: {
                       integrationId: selectedIntegration,
                       integrationType: selectedIntegrationObject.appAttributes.integrationType,
                   },
               }),
               requirement: requirement,
           },
       };

       if (selectedTechnology === 'HELM') {
           payload.appAttributes = {
               helmChartName: kubeNativeFields.name,
               helmChartRepo: kubeNativeFields.repo,
               helmChartVersion: kubeNativeFields.version,
               helmValues: kubeNativeFields.value || '',
               enablePublicAccess: enablePublicAccess,
               serviceName: enablePublicAccess ? serviceName : '',
               servicePort: enablePublicAccess ? servicePort : '',
           };
       }

       return payload;
   };

   const isPayloadValid = (payload) => {
       // Remove `requirement` if it is empty, making it optional
       const payloadForValidation = { ...payload };
       if (!payload.requirement) {
           delete payloadForValidation.requirement;
       }

       const verifyPayload = payloadObjectVerification(payloadForValidation);
       if (verifyPayload && verifyPayload[0] === true) {
           console.log(verifyPayload);
           setformError(verifyPayload[1]);
           return false;
       }
       return true;
   };

   const isAppNameLengthValid = (appName) => {
       const selectedTenantname = tenants?.find((eachTenant) => eachTenant.id == Number(selectedTenant))?.tenantName;
       const maxAppNameLength = Math.round((60 - Number(selectedTenantname?.length)) / 2);

       if (maxAppNameLength < appName.length) {
           setformError('AppNameLength');
           return false;
       }
       return true;
   };

   const hasInvalidAppNameCharacters = (appName) => {
       const invalidCharsPattern = /[., +, *, ?, ^, $, (, ), [,\], {, }, |, \\,@]|[A-Z]/g;
       if (appName.match(invalidCharsPattern)) {
           console.log('AppName contains capital letters or special characters');
           setformError('appName');
           return true;
       }
       return false;
   };

   const submitApplication = async (payload) => {
       setaddApplicationLoading(true);
       console.log('Application payload: ', payload);

       const response = await applicationService.addApplications(payload);

       if (response.status === 200) {
           clearAllFields();
           handleClose();
           dispatch(addApplicationSlice(response.data));
           setaddApplicationLoading(false);
       } else {
           showAlert(response?.response?.data?.errorMessage || 'Failed to create Application', {
               header: response?.response?.data?.message,
           });
       }
   };

   const handleSubmissionError = (err) => {
       console.error('Error during application creation process:', err);
       showAlert('Failed to create Application', { extraInfo: 'Please try again later' });
       setformError('Application creation failed');
   };

   useEffect(() => {
      fetchTenants()
   }, [])

   useEffect(() => {
      fetchApplications()
   }, [selectedTechnology === 'SPRINGBOOT'])

   const handleBlur = () => {
      setnewAppName((prevName) => prevName.replace(/\s+/g, '-'))
   }

   const handleOpenIntegrationModal = () => {
       setOpenReviewModal({
           status: true,
       });
   };

   const handleIntegrationAction = () => {
      setOpenIntegrationModal(false);
      navigate('/integration-hub');
   }

   return (
      <React.Fragment>
         <Dialog
            open={openApplicationDialog}
            onClose={handleClose}
            scroll={'paper'}
            PaperProps={{
               style: { width: '60vw', maxHeight: '70vh', borderRadius: 10 },
               component: 'form',
            }}
         >
            <DialogTitle sx={{ fontWeight: 600 }}>Create Application</DialogTitle>
            <DialogContent>
               <Container>
                  <FormControl fullWidth>
                     <TextField
                        error={formError === 'appName'}
                        margin="dense"
                        id="application-name"
                        label="Application Name"
                        variant="outlined"
                        value={newAppName}
                        onChange={(e) => setnewAppName(e.target.value)}
                        onBlur={handleBlur}
                        size="medium" // Reduce height
                     />
                     <FormHelperText error>
                        {formError === 'AppNameLength' && 'Application Name is exceeding the limit'}
                        {formError === 'appName' &&
                           'Application Name should not contain Speacial characters or uppercase characters'}
                     </FormHelperText>
                  </FormControl>
                  <FormControlLabel
                     control={
                        <Switch
                           checked={useAiAssistant}
                           onChange={() => setUseAiAssistant(!useAiAssistant)}
                           color="primary"
                           sx={{
                              '&.Mui-checked': {
                                 color: 'green',
                              },
                              '& .Mui-checked + .MuiSwitch-track': {
                                 backgroundColor: 'green',
                              },
                           }}
                        />
                     }
                     label="Build With AI Assistance"
                  />
                  <Typography variant="body2" color="textSecondary" style={{ marginTop: '8px', marginBottom: '16px' }}>
                     {useAiAssistant
                        ? "This will allow AI to create an initial version of the application based on your requirements, leaving space for further refinement and iteration in the vision board."
                        : "Or use a basic blueprint to create the application without AI assistance."}
                  </Typography>

                  {useAiAssistant && (
                     <FormControl fullWidth margin="dense">
                        <TextField
                           id="requirement"
                           label="Requirement"
                           variant="outlined"
                           multiline
                           maxRows={4}
                           value={requirement}
                           onChange={(e) => setRequirement(e.target.value)} // Update requirement from TextField
                           size="medium"
                           placeholder="Describe the initial requirements for the application"
                        />
                     </FormControl>
                  )}
                  <SpaceBottom />
                  <FormControl fullWidth error={formError === 'technology'}>
                     <InputLabel id="technology-input">Technology</InputLabel>
                     <Select
                        labelId="technology-select-label"
                        id="technology-select"
                        value={selectedTechnology}
                        label="Technology"
                        onChange={handleChangeTech}
                        size="medium"
                        style={{ display: 'flex' }}
                     >
                        <MenuItem value="REACT" style={{ display: 'flex', alignItems: 'center' }}>
                           <span style={{ display: 'flex', alignItems: 'center' }}>
                              <img src={ReactLogo} alt="React Logo" style={{ width: '30px', marginRight: '5px' }} />
                              React
                           </span>
                        </MenuItem>
                        <MenuItem value="HELM">
                           <span style={{ display: 'flex', alignItems: 'center' }}>
                              <img src={HelmLogo} alt="Helm Logo" style={{ width: '25px', marginRight: '10px' }} />
                              Helm
                           </span>
                        </MenuItem>
                        <MenuItem value="SPRINGBOOT">
                           <span style={{ display: 'flex', alignItems: 'center' }}>
                              <img
                                 src={SpringBootLogo}
                                 alt="Spring Boot Logo"
                                 style={{ width: '20px', marginRight: '15px' }}
                              />
                              Spring Boot
                           </span>
                        </MenuItem>
                        <MenuItem value="NODEJS">
                           <img src={NodeJsLogo} alt="Node JS Logo" style={{ width: '25px', marginRight: '10px' }} />
                           Node JS (Express JS)
                        </MenuItem>
                        <MenuItem value="VUE" disabled>
                           <img src={VueLogo} alt="Vue Logo" style={{ width: '25px', marginRight: '10px' }} />
                           Vue (Coming soon)
                        </MenuItem>
                        <MenuItem value="ANGULAR" disabled>
                           <img src={AngularLogo} alt="Angular Logo" style={{ width: '25px', marginRight: '10px' }} />
                           Angular (Coming soon)
                        </MenuItem>
                     </Select>
                  </FormControl>
                  <SpaceBottom />

                  {getTenentAPILoading ? (
                     <Skeleton variant="rectangular" height={50} />
                  ) : (
                     <FormControl fullWidth error={formError === 'tenantId'}>
                        <InputLabel id="tenant-label">Tenant</InputLabel>
                        <Select
                           labelId="tenant-select-label"
                           id="tenant-select"
                           value={selectedTenant}
                           label="Tenant"
                           onChange={handleChangeTenant}
                           size="medium"
                        >
                           {tenants.map(
                              (tenant: TenantSingleType) =>
                                 tenant.tenantStatus === 'ACTIVE' && (
                                    <MenuItem key={tenant.id} value={tenant.id}>
                                       {tenant.tenantName}
                                    </MenuItem>
                                 )
                           )}
                        </Select>
                     </FormControl>
                  )}
                  <SpaceBottom />
                  {(selectedTechnology === 'NODEJS' || selectedTechnology === 'SPRINGBOOT') &&
                     (getApplicationAPILoading ? (
                        <Skeleton variant="rectangular" height={50} />
                     ) : (
                           <FormControl fullWidth error={formError === 'integration'}>
                              <InputLabel id="integration-label">Integration</InputLabel>
                              <Select
                                 labelId="integration-select-label"
                                 id="integration-select"
                                 value={selectedIntegration}
                                 label="Integration"
                                 onChange={(e) => setSelectedIntegration(e.target.value)}
                                 size="medium"
                              >
                                 {applicationAPIData && applicationAPIData.filter((application: applicationType) => application.appAttributes?.isOpenSourceIntegration === true).map((application: applicationType) => (
                                    <MenuItem key={application.id} value={application.id}>
                                       {application.appName}
                                    </MenuItem>
                                 ))}

                                 <MenuItem key="create-integration" value="" onClick={handleOpenIntegrationModal}>
                                    Create New Integration
                                 </MenuItem>
                              </Select>
                           </FormControl>
                     ))}
                  {selectedTechnology === 'HELM' && (
                     <>
                        <FormControl fullWidth>
                           <TextField
                              margin="dense"
                              id="helm-repo"
                              label="Helm Chart Repository"
                              variant="outlined"
                              value={kubeNativeFields.repo}
                              onChange={(e) => {
                                 setkubeNativeFields({ ...kubeNativeFields, repo: e.target.value })
                              }}
                              size="medium"
                           />
                        </FormControl>
                        <SpaceBottom top="10" />

                        <Grid container spacing={2}>
                           {/* Helm Chart Name */}
                           <Grid xs={8}>
                              <TextField
                                 margin="dense"
                                 id="helm-chart-name"
                                 label="Chart Name"
                                 variant="outlined"
                                 value={kubeNativeFields.name}
                                 onChange={(e) => setkubeNativeFields({ ...kubeNativeFields, name: e.target.value })}
                                 fullWidth
                              />
                           </Grid>

                           {/* Helm Chart Version */}
                           <Grid xs={4}>
                              <TextField
                                 margin="dense"
                                 id="helm-chart-version"
                                 label="Version"
                                 variant="outlined"
                                 value={kubeNativeFields.version}
                                 onChange={(e) => setkubeNativeFields({ ...kubeNativeFields, version: e.target.value })}
                                 fullWidth
                              />
                           </Grid>
                        </Grid>
                        <SpaceBottom top="10" />
                        <FormControl fullWidth>
                           <TextField
                              margin="dense"
                              id="helm-value"
                              label="Helm Chart Value"
                              variant="outlined"
                              multiline
                              maxRows={6}
                              minRows={2}
                              value={kubeNativeFields.value}
                              onChange={(e) => setkubeNativeFields({ ...kubeNativeFields, value: e.target.value })}
                           />
                        </FormControl>
                        <FormControlLabel
                           control={
                              <Checkbox
                                 checked={enablePublicAccess}
                                 onChange={handleEnablePublicAccessChange}
                                 color="primary"
                              />
                           }
                           label="Enable public internet access (HTTPS)"
                        />
                        {enablePublicAccess && (
                           <Grid container spacing={2}>
                              <Grid xs={8}>
                                 <TextField
                                    margin="dense"
                                    label="Service Name"
                                    value={serviceName}
                                    onChange={(e) => setServiceName(e.target.value)}
                                    fullWidth
                                 />
                              </Grid>
                              <Grid xs={4}>
                                 <TextField
                                    margin="dense"
                                    label="Port"
                                    value={servicePort}
                                    onChange={(e) => setServicePort(e.target.value)}
                                    fullWidth
                                 />
                              </Grid>
                           </Grid>
                        )}
                     </>
                  )}
               </Container>
            </DialogContent>
            <Divider />
            <DialogActions>
               <Grid
                  container
                  columnSpacing={2}
                  sx={{
                     marginLeft: 2,
                     marginRight: 'auto',
                     marginBottom: 1,
                     marginTop: 1,
                  }}
               >
                  <Button
                     variant="outlined"
                     color="secondary"
                     onClick={() => setOpenTenantDialog(true)}
                     startIcon={<AddBoxOutlined />}
                  >
                     Add Tenant
                  </Button>
               </Grid>
               <Grid container columnSpacing={2} sx={{ marginRight: 2, marginBottom: 1, marginTop: 1 }}>
                  <Grid>
                     <Button color="primary" onClick={handleClose} variant="outlined">
                        Cancel
                     </Button>
                  </Grid>
                  <Grid>
                     <Button
                        color="primary"
                        variant="contained"
                        onClick={handleAddApplication}
                        disabled={addApplicationLoading}
                     >
                        Create
                     </Button>
                  </Grid>
                  {addApplicationLoading && (
                     <CircularProgress color="primary" size={24} sx={{ marginTop: '6px', marginLeft: '8px' }} />
                  )}
               </Grid>
            </DialogActions>
         </Dialog>
         {openTenantDialog && (
            <CreateTenantDialog openTenantDialog={openTenantDialog} setOpenTenantDialog={setOpenTenantDialog} />
         )}

         <ReviewModal
             openReviewModal={openReviewModal.status}
             setOpenReviewModal={setOpenReviewModal}
             handleAction={handleIntegrationAction}
             message={`This will redirect you to the Integration Hub. Do you wish to proceed?`}
         />
      </React.Fragment>
   )
}
export default CreateApplicationDialog
