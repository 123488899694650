import React from 'react';
import { useEffect } from 'react';
import {
    PageContainer,
    PageContainerApp,
    PageSubTitle,
    PageTitle,
} from '../Devops/CommonStyles';
import { OpenInNew, Assessment, Security, Storage, NetworkCheck, Apps, Business, ListAlt } from '@mui/icons-material';
import {
    Button,
    Grid,
    Card,
    CardContent,
    Typography,
    Tooltip,
} from '@mui/material';
import styled from 'styled-components';

const sections = [
    {
        title: 'Logs',
        description: 'View and analyze logs for troubleshooting and monitoring.',
        icon: <ListAlt style={{ color: '#ff9800' }} />,
        link: 'https://grafana.colate.io/d/o6-BGgnnk/loki-kubernetes-logs?orgId=1',
    },
    {
        title: 'Application Metrics',
        description: 'Monitor the health and performance of your applications.',
        icon: <Apps style={{ color: '#388e3c' }} />,
        link: 'https://grafana.colate.io/d/TrvLHrBSz/co-create-metrics?orgId=1&var-datasource=P1809F7CD0C75ACF3&var-namespace=dev-env-default',
    },
    {
        title: 'Infrastructure Metrics',
        description: 'Monitor the health and performance of your nodes and pods.',
        icon: <Assessment style={{ color: '#1976d2' }} />,
        link: 'https://grafana.colate.io/d/TrvLHrBSz/co-create-metrics?orgId=1&var-datasource=P1809F7CD0C75ACF3&var-namespace=dev-env-default',
    },
    {
        title: 'Network Metrics',
        description: 'Analyze network traffic and latency between services.',
        icon: <NetworkCheck style={{ color: '#d32f2f' }} />,
        link: 'https://grafana.colate.io/d/TrvLHrBSz/co-create-metrics?orgId=1&var-datasource=P1809F7CD0C75ACF3&var-namespace=dev-env-default',
        premium: true,
    },
    {
        title: 'Storage Metrics',
        description: 'Monitor all your applications storage usage and performance.',
        icon: <Storage style={{ color: '#fbc02d' }} />,
        link: 'https://grafana.colate.io/d/TrvLHrBSz/co-create-metrics?orgId=1&var-datasource=P1809F7CD0C75ACF3&var-namespace=dev-env-default',
        premium: true,
    },
    {
        title: 'Security Metrics',
        description: 'Keep track of authentication, authorization, and vulnerabilities.',
        icon: <Security style={{ color: '#7b1fa2' }} />,
        link: 'https://grafana.colate.io/d/TrvLHrBSz/co-create-metrics?orgId=1&var-datasource=P1809F7CD0C75ACF3&var-namespace=dev-env-default',
        premium: true,
    }
];

const Observability: React.FC = () => {
    useEffect(() => {
            document.title = "COCREATE | Observability";
        }, []);
    return (
        <PageContainer>
            <PageContainerApp>
                <PageTitle>Observability</PageTitle>
                <PageSubTitle>
                    Overview of the system's observability metrics and logs. Click on the buttons below to go to the respective dashboards in Grafana.
                </PageSubTitle>
                <Grid container spacing={3} sx={{ marginTop: '20px' }}>
                    {sections.map((section, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Card>
                                <CardContent>
                                    <IconContainer>{section.icon}</IconContainer>
                                    <Typography variant="h6">{section.title}</Typography>
                                    <Typography variant="body2" color="textSecondary" paragraph>
                                        {section.description}
                                    </Typography>
                                    <Tooltip
                                        title={section.premium ? "Premium feature - Contact Us to enable this" : ""}
                                        arrow
                                    >
                                        <span>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => window.open(section.link)}
                                                startIcon={<OpenInNew />}
                                                fullWidth
                                                disabled={section.premium}
                                            >
                                                View Dashboard
                                            </Button>
                                        </span>
                                    </Tooltip>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </PageContainerApp>
        </PageContainer>
    );
};

export default Observability;

const ButtonContainer = styled.div`
    display: flex;
    gap: 16px;
    margin: 20px 0;
`;

const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
`;
